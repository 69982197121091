import { v4 as uuidv4 } from 'uuid';
import { useAppStore, useAppDispatch } from '../../../app/hooks';
import { AppState } from '../../../app/store/store';
import { addItem, selectContactTags } from '../../../slices/tagsBoardSlice';
import { actions as boardActions } from '../../../slices/boardSlice';
import { selectContactsMap } from '../../../slices/contactsSlice';
import useDuplicateContact from './useDuplicateContact';
import { trackEvent } from 'src/analytics/amplitude';
import useFBAnalytics from 'src/analytics/FB/ui/useFBAnalytics';
import FB_EVENTS from 'src/analytics/FB/domain/enums/FB_EVENTS';
import { useLabelsLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

export default function useAttachContactToTag() {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const { trackEvent: FBTrackEvent } = useFBAnalytics();
  const { checkLimits } = useLabelsLimits();

  const duplicateContact = useDuplicateContact();

  return ({
    contactId,
    tagId,
    destIndex = 0,
  }: {
    contactId: string;
    tagId: string;
    destIndex?: number;
  }) => {
    if (!checkLimits(contactId)) return;
    const state = store.getState() as AppState;

    const contact = selectContactsMap(state)[contactId];
    const selectedTags = selectContactTags(contactId)(state);
    if (selectedTags?.length) {
      trackEvent('Edit_tag', { action: 'From plus button' });
    } else {
      trackEvent('Add_first_tag', { action: 'From plus button' });
    }

    FBTrackEvent('trackCustom', FB_EVENTS.ADD_LABEL);

    if (contact?.workspace_id === null) {
      const id = uuidv4();

      duplicateContact(contactId, { uuid: id })?.catch(() => {
        dispatch(boardActions?.setSyncFailed());
      });

      dispatch(
        addItem({
          destColumnId: tagId,
          destIndex: destIndex,
          itemTypeId: id,
        })
      );
    } else {
      dispatch(
        addItem({
          destColumnId: tagId,
          destIndex: destIndex,
          itemTypeId: contactId,
        })
      );
    }
  };
}
